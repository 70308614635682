import React from 'react'
import {useState, useEffect} from 'react'

import { collection, getDoc, getDocs, addDoc, setDoc, doc, onSnapshot, deleteDoc } from "firebase/firestore";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import Icon from '@material-ui/core/Icon';
// import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';


export const TopBar = (props) => {

	const [pName,setPName] = useState("API Key")

	useEffect(()=>{
 		setPName("API Key")
	},[])

	const TopBarClick = (n) => {
		console.log("Got the click")
	}

	const ClearPastJobs = async () => {
		console.log("Clearing past jobs",props.state.getFire())
        await getDocs(collection(props.state.getFire().db,"api","api","pastjobs"))
        .then((qs)=>{
        	    qs.forEach((d)=>
                   {deleteDoc(doc(props.state.getFire().db,"api","api","pastjobs",d.id))}
              )})
    }

	return (
       <Box sx={{ flexGrow: 1 }}>
	      <AppBar position="static">  {/* sx={{height:'30px'}} */}
	        <Toolbar>
	{/*           <IconButton
	            size="small"
	            edge="start"
	            color="inherit"
	            aria-label="menu"
	            sx={{ mr: 2 }}
	          >
	             <MenuIcon onClick={()=>console.log("hamburger")}/> 
	          </IconButton>
	          <Menu>
		           <MenuItem onClick={projects}>
			          	<Typography variant="h6" component="div" sx={{ flexGrow: 1 }} >
			           	 Projects
			          	</Typography>
		          </MenuItem>
	          </Menu>*/}
	        <Box sx={{flexGrow: 1}} >
	        	<Button color="inherit" onClick={()=>TopBarClick("API")}>
		        	<Typography id="topBarProject">
		        	  API Key
		        	</Typography>
		        	{/*<Icon>ArrowCircleDown</Icon>*/}
	        	</Button>
	        </Box>
	        <Button color="inherit" onClick={()=>ClearPastJobs()}>
		        	<Typography id="topBarProject">
		        	  ClearPastJobs
		        	</Typography>
		        	{/*<Icon>ArrowCircleDown</Icon>*/}
	        	</Button>
	          {props.state.getComponent("SignInOut")}
	        </Toolbar>
	      </AppBar>
	    </Box>
      )
}
import react from 'react';
import {useEffect, useState} from 'react'
import './App.css';


import {state} from "./state/state"

import {TopBar} from "./components/topbar"
import {LandingPage} from "./components/landing"
import {SplashPage} from "./components/splash"
//import {ProjectsPage} from "./components/projects"
//import {ActiveProject} from "./components/project"


//const SignInOut = <SignInBlock state={state}/>

function App() {

  // State Machine
  const [machine,setMachine] = useState(state.currentState())
  useEffect(() => {
    if (machine != "Out") {document.body.style.backgroundColor = "rgba(245,245,250,1)";}
    else document.body.style.backgroundColor = "rgba(0,0,0,0.5)";
  },[machine])
  state.registerForStateChange((s)=>{if (machine === state.currentState()) return; setMachine(state.currentState())})
  switch(machine) {
    case "Out":       return (<div><SplashPage     state={state}/></div>)
    case "Land":      return (<div><TopBar state={state}/> <LandingPage    state={state}/></div>)
//    case "Projects":  return (<div><TopBar state={state}/> <ProjectsPage   state={state}/></div>)
 //   case "Active":    return (<div><TopBar state={state}/> <ActiveProject  state={state}/></div>)
    default: {return (<div><TopBar state={state}/><div>Orchestrate Marketplace coming soon</div></div>)}
  }

}

export default App;



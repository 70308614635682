
import {fire, SignInBlock} from './firebase'

import { collection, getDocs, getDoc, setDoc, addDoc, doc, onSnapshot, deleteDoc, updateDoc } from "firebase/firestore";


class GlobalState {
	constructor (f) {
		this.machine = {					// the state machine
			currentState: "Out",
			validStates: ["Out","Land", "Projects", "Active"],
			notify: []
		}
		this.user = null
		this.components = {}				// components that should render across multiple pages
		this.fire = f

		this.collections = {}
	}

	// State Machine functions
	registerForStateChange(f) 	{this.machine.notify.push(f)}
	changeState(s) 				{this.machine.currentState = s;this.machine.notify.map((f)=>f(s))}
	currentState() 				{return this.machine.currentState}
	signIn() 					{this.changeState("Land")}
	signOut() 					{this.changeState("Out")}
	isSignedIn() 				{return (this.machine.currentState != "Out")}

	// User functions
	setUser(u)					{this.user = u;}
	getUser()					{return this.user}

	// Jobs
	getJobs()				{return this.jobs}

	// Components
	addComponent(n,c)			{this.components[n] = c;}
	getComponent(n)				{return this.components[n]}
	getComponents()				{return this.components}

	// Firebase
	setFire(f)					{this.fire = f}
	getFire()					{return this.fire}
}

// State: Load defaults and attach Global Components and Firebase
//const js = new JobState(fire)
const js = null
const state = new GlobalState(fire);
state.addComponent("SignInOut",<SignInBlock state={state}/>)
//state.setFire(fire)

export {state}
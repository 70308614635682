import react from 'react'
import {useState, useEffect} from 'react'

import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebaseui/dist/firebaseui.css'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";

import { getStorage, ref } from "firebase/storage";

import Button from '@mui/material/Button';

var firebaseui = require('firebaseui');


console.log("THIS IS THE FIREBASE CONFIG FILE")

const firebaseConfig = {
  apiKey: "AIzaSyBzDOvW1_La5Wvr7-28YV3v2LxNoV5gATA",
  authDomain: "orchestrate-7dfb7.firebaseapp.com",
  projectId: "orchestrate-7dfb7",
  storageBucket: "orchestrate-7dfb7.appspot.com",
  messagingSenderId: "279927665643",
  appId: "1:279927665643:web:4d0b6b72a25703582a1fae",
  measurementId: "G-GZP2PMZLGL"
};

const app = firebase.initializeApp(firebaseConfig)
const ui  = new firebaseui.auth.AuthUI(firebase.auth())
const auth = getAuth()
const db = getFirestore(app)
const storage = getStorage(app, "gs://orchestrate-7dfb7.appspot.com");


export const fire = 
 {base:        firebase,
  config:      firebaseConfig,
  app:         app,
//  analytics:   getAnalytics(app),
//  ui:          ui,
  db:          db,
  storage:     storage,
 }

 //===== AUTH


export const SignInBlock = (props) => {
  const [signedIn,setSignedIn] = useState(false)

  onAuthStateChanged(auth, (user) => {
    if (user) {
      if (signedIn) return;   // no change
      props.state.setUser(user);
      props.state.signIn();
      setSignedIn(true);
    }
    else {
      if (!signedIn) return;  // no change
      props.state.signOut();
      setSignedIn(false);
    }

  });

  const uiConfig = {
    callbacks: {'signInSuccessWithAuthResult': function(){return false}},  // no need for signInSuccessUrl
    signInFlow: 'popup',
    signInOptions: [
         {provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
         requireDisplayName:true},
         firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
    signInSuccessUrl: 'app',
  };

  const doSignIn = () => {
    document.getElementById('signInButton').style.display = 'none';
    return ui.start("#fb-auth",uiConfig)
  }

  const doSignOut = () => {
    return firebase.auth().signOut()
  }

  if (signedIn) {  // signed in
    return (<div><Button color="inherit" onClick={doSignOut}>Sign Out</Button></div>)
  }
  else {
    return (
      <div>
        <Button color="inherit" id="signInButton" onClick={doSignIn}>Sign In</Button>
        <div id="fb-auth"></div>
      </div>
   )}
}










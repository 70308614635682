import react from 'react'
import {useEffect, useState} from 'react'

import {TopBar} from "./topbar.js"

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Icon from '@material-ui/core/Icon';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TextField from '@mui/material/TextField'

import ReactJson from 'react-json-view'

import { getStorage, ref, uploadBytes } from "firebase/storage";

import { collection, getDoc, getDocs, addDoc, doc, onSnapshot } from "firebase/firestore";


export const LandingPage = (props) => {

    const [jobs,setJobs] = useState(null)
    const [pjobs,setPJobs] = useState(null)

    useEffect(()=>{  // on first load, check if there is a new project queued in the state; if so, create it.
        readJobs();
        const unsub = onSnapshot(collection(props.state.getFire().db,"api","api","jobs"), (d) => readJobs())
        return (() => unsub())
    },[])

    const readJobs = () => {
        const tmp = []
        try {getDocs(collection(props.state.getFire().db,"api","api","jobs"))
        .then((jobs)=>{
            Object.entries(jobs.docs).forEach(([k,prov])=>{
                tmp.push(prov.data())
            })
        })
        .then(()=>{console.log("Jobs",tmp); setJobs({jobs:tmp})})}
        catch {setJobs([])}
    }

    useEffect(()=>{  // on first load, check if there is a new project queued in the state; if so, create it.
        readPJobs();
        const unsub = onSnapshot(collection(props.state.getFire().db,"api","api","pastjobs"), (d) => readPJobs())
        return (() => unsub())
    },[])

    const readPJobs = () => {
        const tmp = []
        try {getDocs(collection(props.state.getFire().db,"api","api","pastjobs"))
        .then((jobs)=>{
            Object.entries(jobs.docs).forEach(([k,prov])=>{
                tmp.push(prov.data())
            })
        })
        .then(()=>{console.log("PJobs",tmp); setPJobs({jobs:tmp})})}
        catch {setPJobs([])}
    }

 //   console.log("Rendering with ",props.state.getFire())

    return (
        <>
        {(jobs === null || jobs === []) && <Box sx={{borderWidth:5, textAlign:"center", width:"100%"}}>
                                <p>No current jobs</p>
                             </Box>
        }
        {(jobs != null && jobs != []) &&
         <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="50%">Current Jobs</TableCell>
                    <TableCell >Stage</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs.jobs.map((row) => (
                    <TableRow
                      key={row.jobID}
                      sx={{ p:-3, my:-3, '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell width="50%"><Box sx={{ p:-3, my:-2}}>{row.service.display}</Box></TableCell>
                      <TableCell ><Box sx={{ p:-3, my:-2}}>{row.stage}</Box></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
        </TableContainer>
        }
        <hr/>
        {(pjobs === null || pjobs === []) && <Box sx={{borderWidth:5, textAlign:"center", width:"100%"}}>
                                <p>No past jobs</p>
                             </Box>
        }
        {(pjobs != null && pjobs != []) &&
         <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="50%">Past Providers</TableCell>
                    <TableCell >Service</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pjobs.jobs.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ p:-1, m:-1, '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell width="50%"><Box sx={{ p:-3, my:-2}}>{row.provider}</Box></TableCell>
                      <TableCell ><Box sx={{ p:-3, my:-2}}>{row.service}</Box></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
        </TableContainer>
        }
        </>
    )
}

